"use client";

import { useEffect } from "react";
import { useRouter } from "next/navigation";

import { useAuth } from "@/context";
import { REDIRECT_KEY } from "@/lib/constants";
import { storage } from "@/lib/utils";

export default function CheckLoggedIn() {
  const { isAuth } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (isAuth) {
      router.replace(storage.get<string>(REDIRECT_KEY) || "/");
      storage.remove(REDIRECT_KEY);
    }
  }, [isAuth]);

  return null;
}
